import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import VideoItems from "./items-video";

export default function () {
    const query = useStaticQuery(graphql`
        query VideoList {
                allYoutubeVideo {
                    edges {
                        node {
                            id
                            title
                            description
                            # localThumbnail {
                            #   height
                            #   width

                            # }
                            thumbnail {
                                url
                                width
                                height
                            }
                            videoId
                            publishedAt
                            privacyStatus
                            channelTitle
                        }
                    }
                }

        }
   
 
    `);

    return (
        <section id="portfolio" className="container">
            <VideoItems data={query} />
        </section>
    );
}
